<template>
  <v-text-field
    v-model="input"
    :hint="hint"
    :label="label"
    :loading="loading"
    :mask="mask"
    :required="required"
    :rules="rules"
    v-bind="binding"
    clearable
    box
    counter="18"
    validate-on-blur
  />
</template>

<script>
import inputControl from '@/mixins/input-control'
import { createGetParams } from '@/rest'

export default {
  mixins: [inputControl],
  props: {
    blankSim: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      counter: null,
      hint: null,
      iccidMask: '## #### #### #### ####',
      inputError: null,
      loading: false,
      source: null,
    }
  },
  computed: {
    binding: function () {
      return this.noIcon ? {} : { prependIcon: 'sim_card' }
    },
    mask: function () {
      if (!this.input) return this.iccidMask

      const mask = this.input.startsWith('89') ? this.iccidMask
        : !this.input.startsWith('8') ? '89 #### #### #### ####'
          : this.input.length === 1 ? this.iccidMask
            : !this.input.startsWith('89') ? '89 #### #### #### ####'
              : this.iccidMask
      return mask
    },
  },
  watch: {
    input: function (val) {
      this.cancelRequest()
      val && val.length === 18 && this.validateIccid()
    },
  },
  mounted: function () {
    if (this.rules) {
      const localRules = this.rules
      localRules.push(val => (val && val.length === 18) || 'Invalid serial number!')
      localRules.push(val => !this.inputError || this.inputError)
      this.$emit('update:rules', localRules)
    }
  },
  methods: {
    cancelRequest: function () {
      this.validate()
      this.inputError = null
      if (this.source) this.source.cancel('Forced cancellation.')
      this.source = null
      this.loading = false
      this.hint = ''
    },
    validateIccid: function () {
      const params = createGetParams({
        iccid: this.input,
      })

      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.loading = true
      this.$rest.get('validateIccid.php', params)
        .then(response => {
          this.loading = false
          const msisdn = String(response.data)
          if (this.blankSim && msisdn && msisdn.length) {
            this.inputError = 'Iccid is unavailable!'
            this.validate(true)
          }
        }).catch(e => {
          this.loading = false
          if (this.$rest.isCancel(e)) return

          if (e.response && e.response.status === 404) {
            this.inputError = 'No such Iccid!'
            this.validate(true)
          } else {
            this.inputError = e.message
            this.validate(true)
          }
        })
    },
  },
}
</script>
