import { getInputs } from '@/utils'

export default {
  props: {
    label: null,
    required: {
      type: Boolean,
      default: false,
    },
    value: null,
  },
  data () {
    return {
      input: '',
      reset: () => {},
      validate: () => true,
      focus: () => {},
    }
  },
  mounted: function () {
    if (this.value) this.input = this.value
    this.inputTimeout = setInterval(this.inputReady, 333)
  },
  watch: {
    input: function (val) {
      if (this.input !== this.value) this.$emit('input', val)
      val && this.validate()
    },
    value: function (val) {
      if (val) this.input = val
      else this.reset()
    },
  },
  methods: {
    inputReady: function () {
      const input = getInputs(this)
      if (input.length) {
        clearInterval(this.inputTimeout)
        this.validate = input[0].validate
        this.reset = input[0].reset
        this.focus = input[0].focus
      }
    },
  },
}
